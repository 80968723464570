.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.card {
  margin-top: 20px;
  font-size: 32pt;
  text-align: left;
  width: 720px
}

.card input {
  font-size: 32pt;
  width: 3em;
  margin-right: 10px;
}